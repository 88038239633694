<div class="results" *ngIf="(loaded | async) && !(loading | async)">
  <h1 class="results__title">Recommended Films</h1>
  <button class="results__button" routerLink="/landing-page" (click)="restart()">Restart</button>
  <div class="results__movies">
    <div class="results__movie" *ngFor="let movie of movies; let i = index">
      <h4 class="results__movie__title">{{ movie.title }}</h4>
      <p class="results__movie__text"><span class="results__bold">Year:</span> {{ getYear(movie.release_date) }}</p>
      <p class="results__movie__text"><span class="results__bold">Runtime:</span> {{ movie.runtime + " mins" }}</p>
      <p class="results__movie__text"><span class="results__bold">Rating:</span> {{ movie.vote_average + "/10" }}</p>
      <p class="results__movie__text"><span
        class="results__bold">Overview:</span> {{ movie.overview.length > 100 ? movie.overview.slice(0, 100) + '...' : movie.overview }}
      </p>
      <p class="results__movie__text"><span class="results__bold">Genres:</span> {{ movieGenres(movie.genres) }}</p>
    </div>
  </div>
</div>
<app-loader *ngIf="(loading | async)"></app-loader>

